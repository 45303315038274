import React from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { AppBar, Toolbar } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useTheme } from '@mui/material/styles';
import { blue, grey } from '@mui/material/colors';

import CreateIcon from '@mui/icons-material/Create';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

type RESULT = {
  input: string,
  results: string[],
}

function App() {
  const theme = useTheme();

	// const url = `${process.env.REACT_APP_API_ENDPOINT_URL}`;
  const url = "https://ojdhfy1e1i.execute-api.ap-northeast-1.amazonaws.com/develop/"

  const [text, setText] = React.useState("");
  const [inputText, setInputText] = React.useState("");
  const [resultTexts, setResultTexts] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  // const [path, setPath] = React.useState('dummy');  // develop
  const [path, setPath] = React.useState('desc');  // prod
  const [isOpening, setIsOpening] = React.useState(false);

  const handleChangePath = (
    event: React.MouseEvent<HTMLElement>,
    newPath: string,
  ) => {
    setPath(newPath);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsOpening(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpening(false);
  };

	// const GetData = () => {
  //   axios.get(url).then((res: AxiosResponse<HELLO>) => {
  //     const { data, status } = res;
  //     setData(data);
	// 	});
	// };

  const PostData = (input: string) => {
    setIsLoading(true);  // Loading開始
    axios.post(url+path, {"input": input}).then((res: AxiosResponse<RESULT>) => {
      const { data, status } = res;
      setResultTexts(data.results);
      setInputText(data.input);
		})
    .catch(error => {
      alert("作成失敗：時間をあけてからの再実行をお願いします。")
      // console.log(error, "input");
    })
    .finally(() => {
      setIsLoading(false);  // Loading終了
    })
  }

  return (
    <Stack bgcolor={theme.palette.grey[50]}>
      <AppBar position="static" color='transparent' elevation={0} sx={{ borderBottom: 1, borderColor: 'grey.300', borderWidth: '2px' }}>
        <Container maxWidth="md" disableGutters={true}>
          <Toolbar variant="dense" sx={{ justifyContent: 'center', minHeight: '64px' }}>
            <LocalGroceryStoreIcon color='primary' fontSize="large" sx={{ mr: 1, marginBottom: '2px' }}/>
            <Typography variant="h4" component="a"
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
              }}
            >
              ItemsGPT
            </Typography>
            <div style={{ flexGrow: 1 }}></div>
          </Toolbar>
        </Container>
      </AppBar>
      <Snackbar open={isOpening} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" color="info" elevation={1} sx={{ width: '100%' }}>
          Copied!
        </Alert>
      </Snackbar>
      <Container maxWidth="md" sx={{ pt: 5 }}>
        <Stack direction="column" justifyContent="center" spacing={3}>
          <Grid container justifyContent="center" spacing={6}>
            {/* <Grid item alignItems="flex-end" md={10} xs={12}>
              <ToggleButtonGroup
                color="primary"
                exclusive
                size="small"
                value={path}
                onChange={handleChangePath}
              >
                <ToggleButton value="dummy">dummy</ToggleButton>
                <ToggleButton value="desc">product</ToggleButton>
              </ToggleButtonGroup>
            </Grid> */}
            <Grid item md={10} xs={12}>
              <Stack direction="column" justifyContent="center" spacing={3}>
                <Typography variant="subtitle1">
                  商品名や商品の属性を書いていきます
                </Typography>
                <TextField
                  id="outlined-basic"
                  hiddenLabel
                  placeholder="例：クルーネック セーター 白"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                />
                <Button variant="contained" size="large" endIcon={!isLoading && <CreateIcon />} onClick={() => PostData(text)} sx={{ borderRadius: '12px', fontWeight: 'bold' }}>
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : '説明文の作成'}
                </Button>
              </Stack>
            </Grid>
            {isLoading &&
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography variant="caption" color="grey">
                    初回実行時には時間がかかる場合があります
                  </Typography>
              </Grid>
            }
            <Grid item xs={12}>
              {!isLoading &&
                <Stack direction="column" justifyContent="center" spacing={3}>
                  <Typography variant="subtitle1">
                    {inputText && "\""+inputText+"\" の商品説明"}
                  </Typography>
                  {resultTexts.map(resultText =>
                    <Paper variant="outlined" elevation={0} style={{
                      padding: 24,
                      paddingTop: 6,
                      // backgroundColor: theme.palette.grey[50],
                      // backgroundColor: blue[50],
                      // border: "1px solid aliceblue",
                    }}>
                      <Grid container justifyContent="flex-end" spacing={0}>
                        <Grid item container justifyContent="flex-end" xs={12}>
                          <Button size="small" endIcon={<ContentCopyIcon fontSize="small"/>} onClick={() => handleCopy(resultText)}>
                            copy
                          </Button>
                        </Grid>
                        <Grid item xs={12} color={grey[800]}>{resultText}</Grid>
                      </Grid>
                    </Paper>
                  )}
                </Stack>
              }
            </Grid>
            <Grid item md={12}></Grid>
          </Grid>
        </Stack>
      </Container>
    </Stack>
  );
}

export default App;
